$(function () {

    $(document).on('click', '.download', function (e) {
        if ($(this).attr('data-generated') === '0') {
            alertify.error('This report is still being generated.');
            e.preventDefault();
        }
    });

    $(document).on('click', '.email-download', function (e) {
        var filename = $(this).attr('data-filename');

        $.ajax({
            type: 'GET',
            url: '/Reports/emailReport',
            data: {
                'filename': filename
            },
            success: function () {
                alertify.success('Report will be delivered via email.');
            }
        });

        e.preventDefault();
    });
    

    $(document).on('click', '.add-cc', function (e) {
        $(this).parent().parent().children('div .cc-email-row').toggleClass('hidden');
    });

    $(document).on('click', '.cc-email-download', function (e) {
        var filename = $(this).attr('data-filename');
        var ccEmailInput = $(this).parent().children('input.download-input');
        var ccEmail = ccEmailInput.val();
        var isValidEmail = isEmail(ccEmail);
        if (isValidEmail) {
            ccEmailInput.removeClass('validation-error');
            $(this).parent().children('.validation-message').html('');

            $.ajax({
                type: 'GET',
                url: '/Reports/emailReport',
                data: {
                    'filename': filename,
                    'cc-email': ccEmail
                },
                success: function () {
                    alertify.success('Report will be delivered via email.');
                }
            });
        }
        else {
            ccEmailInput.addClass('validation-error');
            $(this).parent().children('.validation-message').html('Please enter a valid email address.');
        }

        e.preventDefault();
    });


    $("#downloads-modal").on("shown.bs.modal", function () { 
        $.ajax({
            type: 'GET',
            url: '/downloads/',
            success: function (response) {
                $("#downloads-modal-content").html(response);
            }
        });
    });
    
    $(document).on('click', '.trigger-download-modal', function() {
        alertify.closeAll();
        $('#downloads-modal').modal('show');
    });
    
});

function isEmail(email) {
    var EmailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return EmailRegex.test(email);
  }